import port01 from "../assets/img/port01.jpg";

export const headerNav = [
    {
        title: "intro",
        url: "#intro"
    },
    {
        title: "skill",
        url: "#skill"
    },
    {
        title: "site",
        url: "#site"
    },
    {
        title: "contact",
        url: "#contact"
    }
];

export const introText = {
    title: "Design Publisher",
    desc: ["Hello", "steadily without change", "run"]
}

export const skillText = [
    {
        title: "꿈을 설계하고 디자인하다.",
        desc: "나는 공간을 만드는 것을 좋아한다. 어려을 때부터 나만의 공간을 만드는 것을 좋아했고 나만의 다락방을 좋아했다. 단 한 사람이라도 내가 만든 공간 속에서 영감을 받거나 마음이 움직였으면 좋겠다. 나만의 공간을 마음것 만들 수 있다는 건 코딩에 엄청난 매력인거 같다."
    },
    {
        title: "열심히 할수록 기회는 따른다.",
        desc: "운이 좋은 사람은 없다. 단지 운을 만들 뿐이다. 운을 만들기 위해서는 내가 좋아하는 일이나 내가 하고 싶은 일에 몰두하면 된다. 몰두 하다보면 길이 보이고 방향이 보이게 된다. 운이란 고된 노동과 노력을 통해 스스로 만들어 내는 것이다."
    }
]

export const siteText = [
    {
        text: ["make", "site Joongangcyber"],
        title: "중앙사이버평생교육원 웹접근성",
        view: "https://www.joongangcyber.com/",
        info: [
            "site coding",
            "production period : two month",
            "use stack : HTML5/CSS3, CSS Variable, jQuery",
        ],
    },
    {
        text: ["make", "site renewal joonganghrd"],
        title: "중앙HRD 리뉴얼",
        view: "https://www.joonganghrd.com/",
        info: [
            "site coding",
            "production period : two month",
            "use stack : HTML5/CSS3, CSS Variable, jQuery",
        ],
    },
    {
        text: ["make", "site renewal AALZZA CHILDCARE"],
        title: "알짜 온라인 특별직무 리뉴얼",
        view: "https://www.childcare.ac/",
        info: [
            "site coding",
            "production period : two month",
            "use stack : HTML5/CSS3, CSS Variable, jQuery",
        ],
    },
    {
        text: ["make", "MOBILE site renewal AALZZA CHILDCARE"],
        title: "알짜 온라인 특별직무 모바일 리뉴얼",
        view: "https://m.childcare.ac/",
        info: [
            "site coding, design",
            "production period : one month",
            "use stack : HTML5/CSS3, CSS Variable, jQuery",
        ],
    },
];

export const portText = [
    {
        num: "01",
        title: "아리아나 그란데 포트폴리오",
        desc: "아리아나 그란데 팬사이트 포폴입니다. - 리뉴얼 진행중",
        img: port01,
        code: "http://hansaem2.dothome.co.kr/ari/index%20after3.html",
        view: "http://hansaem2.dothome.co.kr/ari/index%20after3.html",
        name: "아리아나 그란데",
    },
];

export const contactText = [
    {
        link: "mailto:khs_74@dame.net",
        title: "mail : khs_74@dame.net",
    },
];